@import "./variables/layout";
@import "./variables/typography";
@import "./variables/colors";

@import "./components/geosearch";
@import "./components/mainMap";
@import "./components/header";
@import "./components/instructions";
@import "./components/map";
@import "./components/sidebar";
@import "./components/graph";
@import "./components/button";

$phone: 812px;
$desktop: 900px;

html,
body {
  font-size: #{map-get($font-size-base, mobile)};
  font-family: #{map-get($font-family, body)};
  line-height: #{map-get($line-height-base, mobile)};
}

.App {
  @media screen and (max-width: $phone) {
    display: grid;
    grid-template-columns: 30rem 3.5fr;
    grid-template-rows: 5rem 5rem 1fr;
    grid-gap: 0.5rem;
    height: 100%;
    width: 100%;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 30px 0;
  }
  @media screen and #{$bp-desktop-up} {
    height: 100%;
    width: 100%;
    position: relative;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    margin: 3em 0 0 0;
    // font-family: "Roboto", sans-serif;
    // font-weight: 300;
  }
  @media (prefers-color-scheme: dark) {
    @include dark-mode;
  }
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  @media (prefers-color-scheme: dark) {
    background-color: #121212;
  }
}
