@mixin header {
  width: 100vw;
  background-color: white;
  color: black;
}

/** 
 * Header when instructions window is collapsed
 */
.header-container {
  @media #{$bp-desktop-up} {
    @include header;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 5vh;

    .picture {
      @media (prefers-color-scheme: dark) {
        border-radius: 50px;
      }
      max-height: 29px;
      max-width: 199px;
    }
    
    h4 {
      font-size: #{map-get($font-size-h4, desktop)};
      font-weight: #{map-get($font-weight, medium)};
      line-height: #{map-get($line-height-h4, desktop)};
      letter-spacing: 0.0105em;
      text-align: center;
    }

    p {
      font-size: #{map-get($font-size-h4, desktop)};
      font-weight: #{map-get($font-weight, light)};
      line-height: #{map-get($line-height-h4, desktop)};
      letter-spacing: 0.02em;
      text-align: center;
    }

    .line {
      border-left: 0.5px solid black;
      height: 1em;
      margin-left: 1em;
      margin-right: 1em;
      justify-self: center;
    }
  }
}

/** 
 * Header when instructions window is expanded
 */
.header-container-show-instr {
  @media #{$bp-desktop-up} {
    @include header;
    display: grid;
    align-items: center;
    grid-template-columns: 25vw 5vw 25vw 5vw 40vw;
    grid-template-rows: 5vh auto;
    grid-template-areas: 
     "picture line1 header line2 desc"
     "instr instr instr instr instr";

    .picture {
      @media (prefers-color-scheme: dark) {
        border-radius: 50px;
      }
      grid-area: picture;
      max-height: 29px;
      max-width: 199px;
      justify-self: center;
    }
    
    h4 {
      grid-area: header;
      font-size: #{map-get($font-size-h4, desktop)};
      font-weight: #{map-get($font-weight, medium)};
      line-height: #{map-get($line-height-h4, desktop)};
      letter-spacing: 0.0105em;
      text-align: center;
    }

    p {
      grid-area: desc;
      font-size: #{map-get($font-size-h4, desktop)};
      font-weight: #{map-get($font-weight, light)};
      line-height: #{map-get($line-height-h4, desktop)};
      letter-spacing: 0.02em;
      text-align: center;
    }

    .line {
      border-left: 0.5px solid black;
      height: 1em;
      margin-left: 1em;
      margin-right: 1em;
      justify-self: center;
    }

    #line1 {
      grid-area: line1;
    }

    #line2 {
      grid-area: line2;
    }
  }
}