/**
 * Colors
 */

 // Reds
 $color-red: #fa114f;
 $color-firebrick: #D7261B;
 $color-mediumvioletred: #D11D69; 
 $color-deeppink: #FA207C;
 $color-orangered: #ee412a;
 $color-salmon: #ffadad;
 
 // Oranges
 $color-chocolate: #F16618;
 $color-lightorange: #fa9c25;
 $color-orange: #F9B400;
 $color-gold: #F2C94D;
 
 // Yellows
 $color-mustard: #d49f28;
 $color-lightyellow: #ffff97;
 $color-papayawhip: #ffefd5;
 
 // Greens
 $color-forestgreen: #58915b;
 $color-darkolive: #48763e;
 $color-teal: #32ddb1;
 $color-lightseagreen: #0DC583;
 $color-lightgreen: #bbffbb;
 
 // Blues
 $color-midnightblue: #173567;
 $color-darkroyalblue: #01548a;
 $color-blue: #046afa;
 $color-royalblue: #4169e1;
 $color-brightblue: #0000ff;
 $color-azure: #407BFF;
 $color-dodgerblue: #1587FF;
 $color-babyblue: #a4d5ff;
 $color-skyblue: #89cff0;
 
 // Violets
 $color-blueviolet: #571DD1;
 $color-redpurple: #8e1737;
 $color-darkorchid: #981DD1;
 
 // Browns
 
 // Blacks, Greys, White
 $color-black: #333;
 $color-darkblue: #030d2d;
 $color-darkgrey: #464646;
 $color-cement: #767676;
 $color-mediumgrey: #ccc;
 $color-lightgrey: #ededed;
 $color-pink: #f7f5f5;
 $color-whitesmoke: #f6f6f6;
 $color-white: #fff;