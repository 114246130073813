@import "node_modules/react-datepicker/src/stylesheets/datepicker.scss";

$phone: 812px;
$desktop: 900px;

@mixin dark-mode {
  .geosearch__main {
    color: hsl(0, 0%, 87%);
    background-color: #121212;
    border: solid hsl(0, 0%, 38%) 1px;
  }
  input {
    color: hsl(0, 0%, 87%);
  }
  .react-datepicker {
    background-color: #121212;
    color: hsl(0, 0%, 87%);
    border: solid hsl(0, 0%, 38%) 1px;
  }
  .react-datepicker__day,
  .react-datepicker__time-list-item {
    color: hsl(0, 0%, 87%);
    background-color: #121212;
    &:hover {
      color: #b3e89d;
      background-color: black;
    }
    &--selected {
      color: #35b228;
    }
  }
  .react-datepicker__day-name,
  .react-datepicker-time__header,
  .react-datepicker__current-month {
    color: hsl(0, 0%, 87%);
  }
  .react-datepicker__header {
    background-color: #121212;
  }
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    background-color: #121212;
    color: hsl(0, 0%, 87%);
  }
}

.geosearch {
  @media screen and (max-width: $phone) {
    grid-column: 1/3;
    grid-row: 2/3;
    display: flex;
    margin: 0 20px;
    // Disable searching on smaller screens until we fix responsiveness
    display: none;
  }
  @media screen and (min-width: $desktop) {
    position: absolute;
    z-index: 1;
    display: flex;

    // To center geosearch bar on the screen
    margin: 1em 10vw 0 10vw;
    max-width: 80vw;
    width: 100%;
    // End centering

    background-color: #FFFFFF;
    opacity: 0.75;
    border-radius: 50px;
  }
  @media (prefers-color-scheme: dark) {
    @include dark-mode;
  }

  &:hover {
    opacity: 1;
  }
}

.geosearch__main {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1em;
  &--active {
    border: 1px solid lightgray;
    animation: outwardPulse 2s infinite;
    border-color: #47be22;
    transition: border-color 2s;
  }
  &--inactive {
    border: 1px solid lightgray;
  }
  &--disable {
    border: 1px solid #47be22;
    animation: inversePulse 2s infinite;
    border-color: #dd0000;
    transition: border-color 2s;
  }
  @media screen and (max-width: $phone) {
    padding: 2.5rem;
  }
  @media screen and (min-width: $desktop) {
    padding: 0.5rem;
  }
  border-radius: 4rem;
  width: 100%;
  font-family: "Open Sans", "Helvetica Neue", Arial, Helvetica, sans-serif;
}

@keyframes outwardPulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(71, 190, 34, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(71, 190, 34, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(71, 190, 34, 0);
  }
}

@keyframes inversePulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(221, 0, 0, 0);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(221, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(221, 0, 0, 0.4);
  }
}

.geoSearchBarComponents {
  @media screen and (max-width: $phone) {
    display: none;
  }
  @media screen and (min-width: $desktop) {
    padding: 0 24px 0 24px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .react-datepicker-wrapper{      
      .react-datepicker__input-container {
        input {
          width: 100px;
        }
      }
    }
  }
}

.geoSearchBarDateToggle {
  &--visible {
    display: block;
    font-weight: bold;
    font-size: 1em;
    color: #47be22;
    transition: color 1s;
    &:hover {
      color: #dd0000;
      cursor: pointer;
    }
  }
  &--invis {
    display: none;
  }
}

.geosearch__main-time-select {
  border: none;
  -webkit-appearance: none;
  padding: 0.5rem;
  background-color: white;
  border-radius: 0;
  margin-right: 1rem;
  font-size: 30px;
  color: grey;
  position: relative;
}

input {
  margin-left: 1em;
  border: none;
  font-size: 1em;
  color: grey;
}

.toggleDarkMode {
  margin-left: 30rem;
}

.darkModeIcon {
  color: grey;

  &:hover {
    color: black;
  }
}

.testGeoSearch {
  @media screen and (max-width: $phone) {
    width: 100%;
    padding-left: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 24px;
  }
  @media screen and (min-width: $desktop) {
    width: 40%;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 24px;
  }
}

// Todo: style datepicker refactor
.react-datepicker__day-name {
  display: inline-block;
  width: 3rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.35rem;
  font-size: 13px;
}

.react-datepicker__day {
  width: 3rem;
  margin: 0.35rem;
  font-size: 13px;
}
.react-datepicker-time__header {
  font-size: 13px;
}

.react-datepicker__time {
  font-size: 10px;
}
