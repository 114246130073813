@font-face {
  font-family: "DIN1451Alt";
  src: url("../../assets/fonts/alte-din-1451-mittelschrift/din1451alt.ttf")
    format("truetype");
}

@font-face {
  font-family: "VT323";
  src: url("../../assets/fonts/VT323/VT323-Regular.ttf") format("truetype");
}

$phone: 812px;
$desktop: 900px;

@mixin dark-mode {
  .sidebar-container {
    background-color: #121212;
    border: solid hsl(0, 0%, 38%) 1px;
  }
  .sidebar__closeButton {
    background-color: #121212;
    border: solid hsl(0, 0%, 38%) 1px;
  }
  .title {
    color: #b3e89d;
  }
  .desc {
    color: hsl(0, 0%, 87%);
  }
  .left {
    color: hsl(0, 0%, 60%);
    border: solid hsl(0, 0%, 38%) 1px;
  }
  .leftData {
    color: hsl(0, 0%, 87%);
  }
  .bottomLeft {
    color: hsl(0, 0%, 60%);
    border: solid hsl(0, 0%, 38%) 1px;
  }
  .vehicle {
    color: #ffffff;
  }
  .fine {
    color: #ffffff;
  }
  .dollarAmt {
    color: hsl(0, 0%, 87%);
  }
  .dollar {
    color: #35b228;
  }
  .header-text {
    color: #ffffff;
  }
}

.sidebar-container {
  @media screen and (max-width: $phone) {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
    transform: translateY(90rem);
    border-radius: 25px;
  }
  @media screen and (min-width: $desktop) {
    transform: translateY(5rem);
    transform: translateX(-30rem);
    position: absolute;
    padding: 0 2em 1em 2em;
  }

  border: 1px lightgray solid;

  background-color: white;
  transition: all 500ms ease;
  z-index: 1;
}

.extra {
  @media screen and (min-width: $desktop) {
    transform: translateX(-70rem);
  }
}

.mobile-close {
  @media screen and (max-width: $phone) {
    margin-left: 90%;
    margin-top: 5%;
    display: flex;
  }
  @media screen and (min-width: $desktop) {
    display: none;
  }
}

.close-btn {
  @media screen and (max-width: $phone) {
    color: green;
    display: inline-block;
    -moz-animation: pulse 4s infinite linear;
    -o-animation: pulse 4s infinite linear;
    -webkit-animation: pulse 4s infinite linear;
    animation: pulse 4s infinite linear;
  }
  @media screen and (min-width: $desktop) {
    display: none;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

/**
 * Sidebar contents
 */
.content-wrapper {
  height: 100%;

  .header-text {
    font-size: #{map-get($font-size-title3, desktop)};
    font-family: Helvetica, sans-serif;
    font-weight: #{map-get($font-weight, bold)};
    text-align: center;
    padding: 1em 0;
  }
  
  .title {
    text-decoration: underline;
    font-size: #{map-get($font-size-title4, desktop)};
    font-family: Helvetica, sans-serif;
    color: #47be22;
    margin-bottom: 0.2em;
  }
  
  .desc {
    font-family: DIN1451Alt;
    font-size: 0.8em;
    margin-left: 1em;
    margin-bottom: 0.2em;
  }
  
  .vehicle {
    font-family: VT323;
    text-decoration: underline;
    font-size: #{map-get($font-size-title2, desktop)};
    margin: 0.5em 1.5em 0.25em 1.5em;
  }
  
  .left {
    border: solid black 1px;
    font-family: Helvetica, sans-serif;
    font-size: 0.7em;
    // Ensures double overlapping borders do not appear
    margin-bottom: -1px;
    line-height: 1;
  }
  
  .leftData {
    font-family: VT323;
    margin-left: 0.5em;
    font-size: 2em;
  }
  
  .fine {
    font-family: VT323;
    text-decoration: underline;
    font-size: #{map-get($font-size-title2, desktop)};
    margin: 0.5em 0.75em 0.25em 0.75em;
  }
  
  .dollar {
    font-family: VT323;
    font-size: 3em;
    color: #008000;
    margin: 0 0 0 1em;
  }
  
  .dollarAmt {
    font-family: DIN1451Alt;
    font-size: 0.8em;
    color: black;
  }

  @media screen and (max-width: $phone) {
    overflow: scroll !important;
    max-height: 53%;
  }
  @media screen and (min-width: $desktop) {
    display: flex;
    flex-direction: column;
  }
  /**
   * Special case: When the app is rendered on a laptop with shorter viewport height,
   * make the sidebar font sizes and spacing smaller
   */
  @media screen and (max-height: 800px) and (min-width: $desktop) {
    .header-text {
      font-size: #{map-get($font-size-title4, desktop)};
      font-weight: #{map-get($font-weight, bold)};
      text-align: center;
      padding: 0.5em 0;
    }

    .title {
      font-size: #{map-get($font-size-title5, desktop)};
    }
    
    .desc {
      font-size: 0.8em;
      margin-left: 1em;
    }
    
    .vehicle {
      font-size: #{map-get($font-size-title3, desktop)};
      margin: 0.2em 1.5em 0em 1.5em;
    }
    
    .left {
      border: solid black 1px;
      font-family: Helvetica, sans-serif;
      font-size: 0.7em;
      // Ensures double overlapping borders do not appear
      margin-bottom: -1px;
      line-height: 1;
    }
    
    .leftData {
      font-family: VT323;
      margin-left: 0.5em;
      font-size: 2em;
    }
    
    .fine {
      font-size: #{map-get($font-size-title3, desktop)};
      margin: 0.2em 0.75em 0em 0.75em;
    }
  }
}

.--show {
  display: block !important;
}

.--container-open {
  @media screen and (max-width: $phone) {
    //10% max
    transform: translateY(40%);
  }
  @media screen and (min-width: $desktop) {
    transform: translateX(0px);
    transform: translateY(5rem);
  }
}

.--closeButton-close {
  transform: rotate(180deg) translateX(7px);
}

.sidebar__closeButton {
  position: absolute;
  display: inline;
  top: 70px;
  right: -25px;
  height: 50px;
  width: 25px;
  background-color: white;
  border-radius: 3px;
  border: 1px lightgray solid;
  border-left: none;
  cursor: pointer;
  display: none;
}


.sidebar__closeButton--close {
  height: 15px;
  position: relative;
  top: 17px;
  left: 3px;
  background: no-repeat url("../../assets/images/leftArrow.png");
}
