$phone: 812px;
$desktop: 900px;

.map-container {
  height: 100%;
  width: 100vw;
  @media screen and (max-width: $phone) {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
  }
  @media screen and (min-width: $desktop) {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
  }
}
